<template>
  <div class="background: #F6F7FB;">
    <Van-Navbar :title="lang.route_my"></Van-Navbar>
    <div class="user">
      <div class="headImg">
        <UpdateHeadImg ref="HeadImg" @updateImg="updateImg" />
      </div>
      <div class="user_info">
        <span>{{
          setName(
            language == "en"
              ? teacherInfo["en_name"] || teacherInfo["cn_name"]
              : teacherInfo["cn_name"] || teacherInfo["en_name"]
          )
        }}</span>
        <span></span>
      </div>
    </div>
    <div class="operation">
      <van-cell-group>
        <van-cell
          is-link
          :title="lang[item.type]"
          v-for="(item, index) in operationList"
          :key="index"
          @click="clickCurrentCell(item)"
        />
      </van-cell-group>
    </div>
    <Van-Tabbar />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
import VanNavbar from "@/components/common/van-navbar";
import VanTabbar from "@/components/common/van-tabbar";
import UpdateHeadImg from "@/components/common/van-updateheadImg";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
import { Toast, Dialog } from "vant";
// 声明一个全局变量 _this
let _this = this;
export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
    VanTabbar,
    UpdateHeadImg,
  },
  mounted() {
    //   赋值 : _this是 this的引用
    _this = this;
  },
  setup() {
    const HeadImg = ref(null);
    const common = Common;
    const router = useRouter();
    let state = reactive({
      _this: null,
      common: common,
      lang: {
        route_my: "",
        exit_login: "",
        determine: "",
        cancel: "",
        loginOut: "",
        accountInformation: "",
        submit_type_success: "",
      },
      operationList: [
        {
          name: "账号信息",
          type: "accountInformation",
        },
        {
          name: "退出登录",
          type: "loginOut",
        },
      ],
      fileList: [{ url: common.getRes("touxiang_default.png") }],
      teacherInfo: {},
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    onMounted(() => {
      getTeacherInfo();
    });
    const getTeacherInfo = () => {
      state.teacherInfo = JSON.parse(common.getLocal("teacherInfo"));
      if (state.teacherInfo["avatar"] != null) {
        HeadImg.value.fileList[0] = {
          // url: Config["imgUrl"] + state.teacherInfo["avatar"],
          url: state.teacherInfo["avatar"],
        };
      }
    };
    const clickCurrentCell = (item) => {
      console.log("clickCurrentCell", item);
      switch (item.type) {
        case "accountInformation":
          router.push("/accountinformation");
          break;
        case "loginOut":
          logout();
          break;
      }
    };
    const logout = function () {
      console.log("state", state);
      _this.$dialog
        .confirm({
          title: state.lang.exit_login,
          confirmButtonText: state.lang.determine,
          cancelButtonText: state.lang.cancel,
        })
        .then(() => {
          common.removeLocal("token");
          common.removeLocal("phone_number");
          common.removeLocal("password");
          common.removeLocal("teacherInfo");
          router.push({ name: "login" });
        });
    };
    const updateImg = async (urlName) => {
      state.teacherInfo = {
        ...state.teacherInfo,
        avatar: urlName,
      };
      common.setLocal("teacherInfo", JSON.stringify(state.teacherInfo));
      let data = new FormData();
      data.append("avatar", state.teacherInfo["avatar"]);
      data.append(
        "en_name",
        state.teacherInfo["en_name"] == null ? "" : state.teacherInfo["en_name"]
      );
      data.append(
        "cn_name",
        state.teacherInfo["cn_name"] == null ? "" : state.teacherInfo["cn_name"]
      );
      const res = await request.put(`teacher/`, data);
      Toast({
        forbidClick: true,
        message: state.lang.submit_type_success,
      });
    };
    const setName = (name) => {
      console.log("setName", name);
      let str = name == null || name == "" || name == undefined ? "--" : name;
      return str;
    };

    return {
      ...toRefs(state),
      common,
      clickCurrentCell,
      logout,
      getTeacherInfo,
      updateImg,
      setName,
      HeadImg,
    };
  },
};
</script>

<style lang="less" scoped>
.user {
  background: #a5907e;
  padding: 40px;
  display: flex;
  align-items: center;
  .headImg {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    /deep/.van-uploader {
      .van-uploader__upload {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border-radius: 50%;
      }
      .van-uploader__preview {
        margin: 0;
      }
      .van-uploader__preview-image {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
      .van-uploader__wrapper {
        position: relative;

        .van-uploader__upload {
          position: absolute;
          top: 0;
          background: rgba(0, 0, 0, 0);
          .van-icon {
            display: none;
          }
        }
      }
    }
  }
  .user_info {
    margin-left: 30px;
    span {
      display: block;
      &:nth-child(1) {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 56px;
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ded6cf;
        line-height: 42px;
      }
    }
  }
}
.operation {
  margin: 30px 20px;
  /deep/.van-cell {
    padding: 20px 30px;
  }
  /deep/.van-cell__title {
    text-align: left;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    // line-height: 42px;
  }
}
</style>