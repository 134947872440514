<template>
  <div class="vanuploader">
    <van-uploader
      :deletable="false"
      :max-size="10 * 1024 * 1024"
      v-model="fileList"
      @oversize="oversize"
      :before-read="beforeRead"
    />
  </div>
</template>
  
<script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
// 声明一个全局变量 _this
let _this = this;
// const Common = Common;
export default {
  name: "van-updateheadImg",
  components: {},
  mounted() {
    //   赋值 : _this是 this的引用
    _this = this;
  },
  props: {
    defaultImg: {
      type: String,
      // default: "touxiang_default",
      default: Common.getRes("touxiang_default.png"),
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const uploadFile = ref([]);
    const common = Common;
    let state = reactive({
      _this: null,
      common: common,
      lang: {
        justUploadjob: "",
        filenametoolong: "",
      },
      fileList: [{ url: props.defaultImg }],
    });
    common.initLanguage(state.lang);
    const oversize = function () {
      _this.$toast(state.lang.justUploadjob);
    };
    const beforeRead = async (File) => {
      if (File.name.length > 100) {
        Toast(state.lang.filenametoolong);
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", File);
        console.log("formData", formData);
        var uploadRes = await request.upload(formData);
        console.log("uploadRes", uploadRes);
        if (uploadRes.status == 200) {
          state.fileList[0] = {
            url: uploadRes.data.url,
          };
          console.log("uploadRes.data", uploadRes.data);
          getImgName(uploadRes.data.url);
        }
        return true;
      }
    };
    const afterRead = async (event) => {
      console.log("afterRead", event);
      state.fileList = [];
      const formData = new FormData();
      formData.append("file", event.file);
      console.log("formData", formData);
      var uploadRes = await request.upload(formData);
      console.log("formData111", 1111111);
      if (uploadRes.status == 200) {
        state.fileList[0] = {
          url: uploadRes.data.url,
        };
        getImgName(uploadRes.data.url);
      }
    };
    const getImgName = (url) => {
      let index = Config["imgUrl"].length;
      let name = url.substring(index, url.length);
      if (props.type == "student") {
        context.emit("updateImg", {
          index: props.index,
          name: url,
        });
      } else {
        // context.emit("updateImg", name);
        context.emit("updateImg", url);
      }
    };
    return {
      ...toRefs(state),
      common,
      oversize,
      beforeRead,
      afterRead,
      getImgName,
      uploadFile,
    };
  },
};
</script>
  
<style lang="less" scoped>
.vanuploader {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader {
  width: 100%;
  height: 100%;
  .van-uploader__upload {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 50%;
  }
  .van-uploader__preview {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
  }
  .van-uploader__preview-image {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 50%;
  }
  .van-uploader__wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .van-uploader__upload {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0);
      .van-icon {
        display: none;
      }
    }
  }
  .van-uploader__input {
    width: auto;
    height: auto;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
}
</style>