<template>
  <van-tabbar
    class="vantabbar"
    fixed
    route
    placeholder
    safe-area-inset-bottom
    active-color="#A5907E"
    inactive-color="#999999"
  >
    <van-tabbar-item
      v-for="(item, index) in tabbarList"
      :key="index"
      :to="item.path"
    >
      <img :src="active == index ? item.img_active : item.img" />
      <div class="text">{{ lang[item.name] }}</div>
    </van-tabbar-item>
  </van-tabbar>
</template>
  
<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";

export default {
  name: "ClassEvaluation",
  components: {},
  setup() {
    const common = Common;
    let state = reactive({
      common: common,
      active: 0,
      lang: {
        route_home: "",
        route_course: "",
        route_my: "",
      },
      tabbarList: [
        {
          name: "route_home",
          path: "/home",
          img: Config.resAddr + "tabbar_home.png",
          img_active: Config.resAddr + "tabbar_home_active.png",
        },
        {
          name: "route_course",
          path: "/course",
          img: Config.resAddr + "tabbar_course.png",
          img_active: Config.resAddr + "tabbar_course_active.png",
        },
        {
          name: "route_my",
          path: "/my",
          img: Config.resAddr + "tabbar_me.png",
          img_active: Config.resAddr + "tabbar_me_active.png",
        },
      ],
    });
    common.initLanguage(state.lang);
    onMounted(() => {
      window.addEventListener("setItemEvent", function (e) {
        if (e.key === "Language") {
          setTimeout(() => {
            common.initLanguage(state.lang);
          }, 300);
        }
      });
    });
    return {
      ...toRefs(state),
      common,
    };
  },
  created() {
    this.active = this.$route.meta.tabbarNum;
  },
};
</script>
  
<style lang="less" scoped>
.vantabbar {
  /deep/.van-tabbar {
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);
  }
  .text {
    font-size: 24px;
    margin-top: 4px;
  }
}
</style>